import axios from "axios"
import WebService from "./webService";

export const sendFCMUserData = (action, userEmail, userId) => {
    const data = {
        action: action,
        customerId: String(userId),
        userEmail: userEmail
    }

    if (isIos()) {
        window.webkit.messageHandlers.FCMBridge.postMessage(data); // iOS
    } else {
        window.FCMBridge.webLogin(JSON.stringify(data)); //android 
    }
}

export const sendChatData = (action, operatorId="", userId="") => {
    const api = "authorization/key/sendbird"

    WebService.EXT.get(api).then( res => {
        const app_id = res.REACT_APP_SB_APP_ID
        const app_token = res.REACT_APP_SB_APP_TOKEN

        const params = {
            user_ids: [userId, operatorId],
            operator_ids: [operatorId],
            is_distinct: true,
        };

        const headers = {
            "Content-type": "application/json",
            "api-token": app_token,
        };

        if (userId !== "") {
            try {
                axios.post(`https://api-${app_id}.sendbird.com/v3/group_channels`, params, {
                    headers: headers,
                }).then((res) => {
                    const data = {
                        action: action,
                        customerId: userId,
                        operatorId: operatorId,
                        chatUrl: res.data["channel_url"]
                    }
                    alert(`sendUserchatData : action=${action}, userId=${userId}, operatorId=${operatorId}, url=${res.data["channel_url"]}`)
        
                    if (isIos()) {
                        window.webkit.messageHandlers.iOSBridge.postMessage(data); // iOS
                    } else {
                        window.AndroidBridge.chatData(JSON.stringify(data)); //android 
                    }
                }).catch((err) => {
                    if (err.response) {
                        alert(
                            `ERR : ${JSON.stringify(err.response.status)} - 
                                ${JSON.stringify(err.response.data)}`
                        );
                    } else if (err.request) {
                        alert(`ERR : No response received - ${JSON.stringify(err.request)}`);
                    } else {
                        alert(`ERR : ${err.message}`);
                    }
                });
            } catch (error) {
                alert(`Error in axios : ${error}`);
            }
        } else {
            const data = {
                action : action,
                operatorId : operatorId
            }
    
            if (isIos()) {
                // window.webkit.messageHandlers.iOSPopupBridge.postMessage(data); // iOS
                window.webkit.messageHandlers.iOSBridge.postMessage(data); // iOS
            } else {
                window.AndroidBridge.chatData(JSON.stringify(data)); //android 
            }
        }
    }).catch( err => {
        console.log(err)
    })
}

export const omnioneCXRedirect = () => {
    console.log("mobilebridge", `url : ${window.location.origin}`)
    const url = window.location.origin
    const data = {
        action : "redirect",
        data : url
    }
    if (isIos()) {
        console.log("mobilebridge", `run iOS`)
        window.webkit.messageHandlers.iOSBridge.postMessage(data) // iOS
    } else {
        console.log("mobilebridge", `run aos`)
        window.AndroidBridge.OACXRedirect(url) //android 
    }
}

export const closePopup = () => {
    console.log("run Close popup")
    const data = {
        action : "closePopup",
        data : "" 
    }
    if (isIos()) {
        return
    } else {
        window.AndroidBridge.PopupClose() //android 
    }
}

const isIos = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}