import { merge } from "lodash";

const domain = window.location.hostname;

export const usUrls = ["sourcingroot.com", "www.sourcingroot.com"];
export const jpUrls = ["sourcingroot.jp", "www.sourcingroot.jp"];
export const krUrls = ["sourcingroot.co.kr", "www.sourcingroot.co.kr"];

export const testUrls = ["test-us.sourcingroot.kr", "test-jp.sourcingroot.kr", "test-kr.sourcingroot.kr", "localhost"];

let config = require("./config_default.json");

if (krUrls.includes(domain)) {
  const krConfig = require("./config_kr.json");
  config = merge({}, config, krConfig);
} else if (jpUrls.includes(domain)) {
  const jpConfig = require("./config_jp.json");
  config = merge({}, config, jpConfig);
} else if (usUrls.includes(domain)) {
  const usConfig = require("./config_us.json");
  config = merge({}, config, usConfig);
} else if (testUrls.includes(domain)) {
  const testConfig = require("./config_test.json");
  config = merge({}, config, testConfig);
}

export default config;
