import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import LayerPopCore from "./LayerPopCore";
import WebService from "./webService";

/* ==============================
* Google Translations Insert Widget
================================= */
/*
export class GoogleTranslateWidget extends Component {
	componentDidMount() {
		this.init();
	}
	init() {
		const script = document.createElement('script');
		script.src = '//translate.google.com/translate_a/element.js?cb=gTransFnc';
		script.async = true;
		document.body.appendChild(script);
		window.gTransFnc = () => {
			new window.google.translate.TranslateElement({
				pageLanguage: 'ko',
				includedLanguages: 'zh-CN,zh-TW,en,ja,vi',
				layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
			}, 'gTrans');
		};
	}
	componentWillUnmount() {
		const script = document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=gTransFnc"]');
		if (script) {
			document.body.removeChild(script);
			delete window.gTransFnc;
		}
	}
	render() {
		return (
			<div id="gTrans" class="gTrans blind"></div>
		)
	}
}
// */

/* ==============================
* Language conversion Entire
================================= */
/*
export class GoogleTranslateEntire extends Component {
	componentDidMount() {
		this.init();
		this.initCast();
	}
	init() {
		this.target = $(this.props.target);
		this.langToggler = $(".langToggle", this.target );
		this.langSet = $(".langSet", this.target )
		this.langSetBtn = $("a", this.langSet );
	}
	initCast() {
		var lang = this;

		//* React Handler 처리.
		// lang.langToggler.on("click", function() {
			// lang.toggleCast();
		// });

		lang.langSetBtn.on("click", function() {
			lang.moveLang( $(this) );
		});

		// ** Hash 감지.
		lang.detectHash();
	}
	toggleCast() {
		var lang = this;
		if ( lang.target.hasClass("on") ) {
			lang.target.removeClass("on");
			lang.langSet.slideUp();
		} else {
			lang.target.addClass("on");
			lang.langSet.slideDown();
		}
	}
	moveLang( btn ) {
		// var thisHash = btn.data("hash");
		// window.location.hash = thisHash;
		// location.reload();

		// setTimeout( function() {
			// window.location.reload();
		// }, 200 );
	}
	detectHash() {
		var lang = this;
		var thisPageHash = window.location.hash.substring(1);
		var thisBtnHasHash = $("a[data-hash='" + thisPageHash + "']", lang.langSet );
		thisBtnHasHash.parent().addClass("on");
	}
	render() { return <></>; }
}
// */

/* ==============================
* Language conversion Part
================================= */
export class GoogleTranslatePart extends Component {
  componentDidMount() {
    this.init();
    this.initCast();
  }
  init() {
    this.target = $(this.props.targetWrap);
    this.targetDestination = $(this.props.targetDestination);
    this.langToggler = $(".langToggle", this.target);
    this.langSet = $(".langSet", this.target);
    this.langSetBtn = $("button", this.langSet);
    this.langSetCloser = $(".langSetCloser", this.langSet);
    this.txtTransUrl = process.env.REACT_APP_TEXT_TRANS;
  }
  initCast() {
    let lang = this;
    lang.target.on("click", ".langCast", function () {
      lang.toggle($(this));
    });
    lang.langSetCloser.on("click", function () {
      lang.closer($(this));
    });
    lang.langSetBtn.on("click", function () {
      lang.trans($(this));
    });
  }
  toggle(btn) {
    let lang = this;
    let wrap = btn.closest(".c-trans1");
    if (wrap.hasClass("on")) {
      wrap.removeClass("on");
      lang.langSet.slideUp("fast", function () {
        $(this).closest(".c-trans1").removeClass("setZ");
      });
    } else {
      wrap.addClass("on setZ");
      lang.langSet.slideDown("fast");
    }
  }
  closer(btn) {
    btn.closest(".c-trans1").removeClass("on");
    btn.parent().slideUp("fast", function () {
      $(this).closest(".c-trans1").removeClass("setZ");
    });
  }
  async trans(btn) {
    let lang = this;
    let targetLang = btn.data("lang");
    let targetNode = lang.targetDestination;
    let targetHtml = targetNode.html();
    let transResult = null;
    let param = {
      originalText: targetHtml,
      targetLanguage: targetLang,
    };
    try {
      let response = await WebService.post(lang.txtTransUrl, param);
      if (response) {
        transResult = response.data.translations[0].translatedText;
        targetNode.html(transResult);
        btn.closest(".langSet").slideUp("fast", function () {
          $(this).closest(".c-trans1").removeClass("setZ");
        });
        btn.closest(".c-trans1").removeClass("on");
      }
    } catch (error) {
      console.error("Image translation error( Over text length ) : ", error);
    } finally {
    }
  }
  render() {
    return <></>;
  }
}

/* ==============================
* Layer Popup
================================= */
export class LayerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.targetBtn = $(this.props.targetBtn);
    const { div_target, url, lineage, dataType, returnObj, title, width, height, close_button_yn, reload_button_yn, minmaxsize_button_yn, extraClass, parameter, translation } = this.props;
    const opt = {};
    if (div_target) {
      opt.div_target = $(div_target);
    }
    if (url) {
      opt.url = url;
    }
    if (lineage) {
      opt.lineage = lineage;
    }
    if (dataType) {
      opt.dataType = dataType;
    }
    if (returnObj) {
      opt.returnObj = $(returnObj);
    }
    if (title) {
      opt.title = title;
    }
    if (width) {
      opt.width = width;
    }
    if (height) {
      opt.height = height;
    }
    if (close_button_yn) {
      opt.close_button_yn = close_button_yn;
    }
    if (reload_button_yn) {
      opt.reload_button_yn = reload_button_yn;
    }
    if (minmaxsize_button_yn) {
      opt.minmaxsize_button_yn = minmaxsize_button_yn;
    }
    if (extraClass) {
      opt.extraClass = extraClass;
    }
    if (parameter) {
      opt.parameter = parameter;
    }
    if (translation) {
      opt.translation = translation;
    }
    this.setState({ opt }, () => {
      this.initCast();
    });
  }
  initCast() {
    let layer = this;
    layer.targetBtn.on("click", function () {
      layer.layerPopCoreInstance = new LayerPopCore(layer.state.opt);
    });
  }
  render() {
    return <></>;
  }
}

/* ==============================
* 숫자화 화폐용
================================= */
export class setNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  setNum() {
    this.number = this.props.number.replace(/[^0-9]/g, "");
    if (typeof this.number === "string") {
      this.number = parseFloat(this.number) / 100;
    }
    return this.number;
  }
}

/* ==============================
* 숫자화
================================= */
export class SetNumberOnly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  setNum() {
    this.number = this.props.number.replace(/[^0-9]/g, "");
    this.number = parseFloat(this.number);
    return this.number;
  }
}

/* ==============================
* 문자화 + 3자리 Comma
================================= */
export class SetCharComma extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetChar: this.props.targetChar,
    };
  }
  setChar() {
    const number = this.state.targetChar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return number;
  }
  render() {
    const formattedNumber = this.setChar();
    return <>{formattedNumber}</>;
  }
}

/* ==============================
* 문자화 + 3자리 Comma 2
================================= */
export class SetCharComma2 extends Component {
  constructor(props) {
    super(props);
  }
  setChar() {
    const { targetChar } = this.props;
    if (typeof targetChar === "number") {
      return targetChar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return targetChar; // targetChar이 문자열이거나 빈 문자열인 경우 처리
    }
  }
  render() {
    const formattedNumber = this.setChar();
    return <>{formattedNumber}</>;
  }
}

/* ==============================
* Datepicker Period controller (상품목록)
================================= */
export class PeriodController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    const rdos = this;
    this.today = new Date();
    this.targetParent = $(this.props.targetParent);
    this.targetRdo = $(this.props.targetRdo);
    this.targetDateS = $(this.props.targetDateS);
    this.targetDateE = $(this.props.targetDateE);
    this.evtCast(this.targetRdo);
    setTimeout(function () {
      rdos.targetRdo.filter("#period3").trigger("click");
    }, 1000);
  }
  evtCast(rdo) {
    let rdos = this;

    /** -- 1일 ~ 3개월 Radio 변경이벤트 감지. */
    rdo.on("change", function () {
      rdos.setDate($(this).attr("id"));
    });

    /** -- Datepicker 변경이벤트 감지. */
    rdos.targetDateS.on("dateChange", function (event) {
      // ** console.log("시작 날짜 변경 : ", event.detail);
      rdos.setRadioRelease();
    });
    rdos.targetDateE.on("dateChange", function (event) {
      // ** console.log("종료 날짜 변경 : ", event.detail);
      rdos.setRadioRelease();
    });
  }
  setRadioRelease() {
    let rdos = this;
    rdos.targetRdo.prop({ checked: false });
  }
  setDate(flag) {
    let rdos = this;
    let todayBefore = new Date(rdos.today);
    if (flag === "period1") {
      todayBefore.setDate(todayBefore.getDate() - 1);
    } else if (flag === "period2") {
      todayBefore.setDate(todayBefore.getDate() - 7);
    } else if (flag === "period3") {
      todayBefore.setMonth(todayBefore.getMonth() - 1);
    } else if (flag === "period4") {
      todayBefore.setMonth(todayBefore.getMonth() - 3);
    }
    rdos.targetDateS.val(rdos.formatting(todayBefore));
    rdos.targetDateE.val(rdos.formatting(rdos.today));
  }
  formatting(date) {
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    return year + "-" + month + "-" + day;
  }
  render() {
    return <></>;
  }
}

/* ==============================
* Item number controller (상품등록)
================================= */
export class PriceItemController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.targetParent = $(this.props.targetParent);
    this.ctrlBtn = $(".unitCtrl", this.targetParent);
    this.itemLim = parseInt(this.props.itemLim);
    this.evtCast();
  }
  evtCast() {
    const price = this;

    /** --  */
    price.targetParent.on("click", ".unitCtrl", function () {
      price.unitCtrl($(this));
    });
  }
  unitCtrl(btn) {
    const price = this;
    if (btn.hasClass("unitMinus")) {
      btn.closest(".unit").remove();
    } else if (btn.hasClass("unitPlus")) {
      if ($(".unit", price.targetParent).length >= price.itemLim) {
        alert("상품가격 등록은 최대 " + price.itemLim + "개까지만 가능합니다.");
        return;
      }
      let unitClone = btn.closest(".unit").clone();
      $("input", unitClone).val("");
      price.targetParent.append(unitClone);
    }
    price.unitArrange();
  }
  unitArrange() {
    const price = this;
    $(".unit", price.targetParent).each(function (index, item) {
      $(".moqMin", $(item)).attr({
        name: "moqMin" + (index + 1),
        id: "moqMin" + (index + 1),
      });
      $(".moqMax", $(item)).attr({
        name: "moqMax" + (index + 1),
        id: "moqMax" + (index + 1),
      });
      $(".priceNum", $(item)).attr({
        name: "price" + (index + 1),
        id: "price" + (index + 1),
      });
    });
  }
  render() {
    return <></>;
  }
}

/* ==============================
* Seller > Product Register
================================= */
export class ProdictRegisterPkg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.targetParent = $(this.props.targetParent);
    this.boxInput = $(".boxInput", this.targetParent);
    this.boxApplied = $(".boxApplied", this.targetParent);
    this.lv1Parent = $(".boxListWr", this.targetParent);
    this.lv1Lim = this.props.lv1Lim;
    this.lv2Parent = null;
    this.lv2Lim = this.props.lv2Lim;
    this.bodyData = this.props.bodyData;
    this.optApply = $(".optApply", this.targetParent);
    this.evtCast();
  }
  evtCast() {
    const opt = this;

    // ** Minus 클릭
    opt.targetParent.on("click", ".minus", function () {
      opt.setMinus($(this));
    });

    // ** Plus 클릭
    opt.targetParent.on("click", ".plus", function () {
      opt.setPlus($(this));
    });

    // ** 옵션 적용
    opt.optApply.on("click", function () {
      opt.setApply();
    });

    opt.targetParent.on("click", ".upImg", function () {
      const inputId = $(this).find('input[type="file"]').attr("id");
      const fileInput = document.getElementById(inputId);
      if (fileInput) {
        fileInput.click(); // input 클릭 이벤트 트리거
      }
    });

    opt.boxInput.on("click", ".radioBtn", function () {
      console.log($(".optImage").length);
      const radioValue = $(this).val();

      $(".optImage").each(function (index) {
        if (index + 1 === parseInt(radioValue)) {
          $(".imageBtn" + radioValue).show(); // radioValue와 일치하는 버튼만 표시
        } else {
          $(".imageBtn" + (index + 1)).hide(); // radioValue와 일치하는 버튼만 표시
          $(".hiddenImage").val("");
          $(".optImageValueId").val("");
          $(".imgView").attr("src", "");
          $(".upImg").show();
          $(".imgView").hide();
          $(".imgDel").hide();
        }
      });
    });

    opt.targetParent.on("change", ".upImg", function (event) {
      const file = event.target.files[0];
      const inputId = $(this).find('input[type="file"]').attr("id");
      const fileId = inputId.replace("optionFile", ""); // 현재 input의 ID 가져오기
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          $("#imgOptionFile" + fileId).show();
          $("#imgOptionFile" + fileId).append("<img src='" + reader.result + "' id='fileImg" + fileId + "' alt='Preview'  class ='imgView'/>");
          $("#imgOptionDel" + fileId).show();
          $("#btnOptionFile" + fileId).hide();
          saveFileImage(reader.result, fileId);
        };
        reader.readAsDataURL(file);
      }

      const getMimeTypeFromDataURI = (dataURI) => {
        const mimeType = dataURI.split(";")[0].replace("data:", ""); // 'data:image/jpeg'에서 'image/jpeg' 부분 추출
        return mimeType;
      };

      const base64ToFileWithName = (base64String, fileName, mimeType) => {
        const base64Data = base64String.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset++) {
          const byteValue = byteCharacters.charCodeAt(offset);
          byteArrays.push(byteValue);
        }

        const byteArray = new Uint8Array(byteArrays);
        const blob = new Blob([byteArray], { type: mimeType });

        // 파일 이름을 받아서 File 객체를 생성
        const file = new File([blob], fileName, { type: mimeType });

        return file;
      };

      const getFileExt = (mimeType) => {
        let fileExt = "";
        if (mimeType === "image/jpeg") {
          fileExt = "jpg";
        } else {
          fileExt = mimeType.replace("image/", "");
        }
        return fileExt;
      };

      const saveFileImage = async (fileImage, idx) => {
        const mimeType = getMimeTypeFromDataURI(fileImage);
        const fileExt = getFileExt(mimeType);
        const now = new Date();
        const dateString = now.toISOString().slice(0, 19).replace(/-/g, "").replace(/:/g, "").replace("T", "-");
        // 6자리 랜덤 숫자
        const randomNumber = Math.floor(100000 + Math.random() * 900000);

        const fileName = "SalesOption/" + `${dateString}-${randomNumber}.` + fileExt;

        const file = base64ToFileWithName(fileImage, fileName, mimeType);
        const modifiedImage = new File([file], fileName, { type: file.type, lastModified: file.lastModified });
        const formData = new FormData();
        formData.append("file", modifiedImage);

        const url = "seller/content/type/file?fileContentType=PRODUCT";
        const res = await WebService.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } });
        if (res) {
          if ($("#optImage" + idx)) {
            $("#optImage" + idx).val(res);
          }
        }
      };
    });

    opt.targetParent.on("click", ".delImg", function () {
      const inputId = $(this).attr("id");
      const fileId = inputId.replace("imgOptionDelFile", "");
      $("#optRow" + fileId + "_image").val("");
      $("#imgOptionFile" + fileId).hide();
      $("#imgOptionDel" + fileId).hide();
      $("#fileImg" + fileId).remove();
      $("#imgOptionFile" + fileId).append("");
      $("#btnOptionFile" + fileId).show();
      $("#optionFile" + fileId).val("");
    });
  }
  setPlus(btn) {
    const opt = this;
    if (btn.hasClass("lv1")) {
      let lv1Len = btn.closest(".boxListWr").find(".boxList").length;
      if (opt.lv1Lim <= lv1Len) {
        alert("최대 " + lv1Len + "개 까지만 설정이 가능합니다.");
        return;
      } else {
        let cloneTarget = btn.closest(".boxList").clone();

        $("input", cloneTarget).val("");
        $("input", cloneTarget).attr("data-id", 0);
        $("input", cloneTarget).attr("readonly", false);
        $(".lv2Unit", cloneTarget).each(function () {
          $(".lv2CloneTarget", $(this)).slice(1).remove();
          $(".lv2CloneTarget", $(this)).find("input").attr("data-chk", "0;0");
          $(".lv2CloneTarget", $(this)).find("input").removeAttr("data-id");
          $(".lv2CloneTarget", $(this)).find("input").attr("readonly", false);
        });
        btn.closest(".boxListWr").append(cloneTarget);
      }
    } else if (btn.hasClass("lv2")) {
      let lv2Len = btn.closest("dd").find(".lv2CloneTarget").length;
      if (opt.lv2Lim <= lv2Len) {
        alert("최대 " + lv2Len + "개 까지만 설정이 가능합니다.");
        return;
      } else {
        let optionId = "";
        $(".lv2Unit", btn.closest(".boxList")).each(function (index, item) {
          let cloneTarget = $(".lv2CloneTarget", $(this)).first().clone();
          if ($("input", cloneTarget).data("chk") !== undefined) optionId = $("input", cloneTarget).data("chk").split(";")[1];

          $("input", cloneTarget).val("");
          $("input", cloneTarget).attr("data-id", 0);
          $("input", cloneTarget).attr("data-chk", "0;" + optionId);
          $("input", cloneTarget).attr("readonly", false);
          $(this).append(cloneTarget);
          let cloneTarget2 = $(".optImage lv2CloneTarget hasImg", $(this)).first().clone();
          $(this).append(cloneTarget2);
        });
      }
    }
    opt.setArrange();
  }
  setMinus(btn) {
    const opt = this;
    if (btn.hasClass("lv1")) {
      btn.closest(".boxList").remove();
    } else if (btn.hasClass("lv2")) {
      let thisIndex = btn.parent().index();
      $(".lv2Unit", btn.closest(".boxList")).each(function (index, item) {
        $(".lv2CloneTarget", $(this)).eq(thisIndex).remove();
      });
    }
    opt.setArrange();
  }
  setArrange() {
    const opt = this;
    $(".boxList", opt.boxInput).each(function (index, item) {
      let orderNum1 = index + 1;

      // ** .boxList 클래스 순번 지정.
      var classes = $(item).attr("class").split(" ");
      var newClasses = classes.filter(function (c) {
        return c === "boxList";
      });
      $(item).attr("class", newClasses.join(" "));
      $(item).addClass("list" + orderNum1);

      // ** 1Depth 이외 input 비활성(disabled) 로직.
      if (index !== 0) {
        $(".optStockInp, .optSkuInp", $(item)).prop({ disabled: true });
      }

      // ** 순번 표시.
      $(".optNum .lv1CloneTarget", $(item)).text(orderNum1);

      // ** 속성 순번 적용.
      opt.setAttrOrder($(item), orderNum1);
    });
  }
  setAttrOrder(el, order1) {
    let orderNum2 = "";

    // ** LV.1 / ID, NAME 순번 재설정.
    $(".optName .optNameInp", el).attr({ id: "optName" + order1, name: "optName" + order1, value: "" });
    $(".optId ", el).attr({ id: "optId" + order1, name: "optId" + order1, value: "" });

    // ** LV.2 / ID, NAME 순번 재설정.
    $(".optValue .lv2CloneTarget", el).each(function (index, item) {
      orderNum2 = index + 1;
      $(".optValueInp", $(item)).attr({ id: "optValue" + order1 + "_" + orderNum2, name: "optValue" + order1 + "_" + orderNum2 });
      $(".optValueInpHidden", $(item)).attr({ id: "optId" + order1 + "_" + orderNum2, name: "optId" + order1 + "_" + orderNum2 });
    });

    $(".optImage .lv2CloneTarget", el).each(function (index, item) {
      orderNum2 = index + 1;

      $(".imgView", $(item)).attr("id", "imgOptionFile" + order1 + "_" + orderNum2);
      $(".hiddenImage", $(item)).attr("id", "optImage" + order1 + "_" + orderNum2);
      $(".hiddenImage", $(item)).val("");

      $(".imgDel", $(item)).attr("id", "imgOptionDel" + order1 + "_" + orderNum2);
      $(".delImg", $(item)).attr("id", "imgOptionDelFile" + order1 + "_" + orderNum2);
      $(".upImg", $(item)).attr("id", "btnOptionFile" + order1 + "_" + orderNum2);
      $(".fileUpload", $(item)).attr("id", "optionFile" + order1 + "_" + orderNum2);
    });
  }
  setApply() {
    const opt = this;
    let dtMarkups = "";
    let ddMarkups = "";

    /* ==============================
		* DT 구성
		================================= */
    dtMarkups += "<ul class='boxList'>";
    dtMarkups += "<li class='optNum'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>No.</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    $(".boxList", opt.boxInput).each(function (index, item) {
      dtMarkups += "<li class='optCore'>";
      dtMarkups += "<dl>";
      dtMarkups += "<dt>";
      dtMarkups += "<input type='text' value='" + $(".optNameInp", $(this)).val() + "' name='optName_" + (index + 1) + "' id='optName_" + (index + 1) + "' readonly=true />";
      dtMarkups += "<input type='hidden' value='" + $(".optNameInp", $(this)).data("id") + "' name='optId_" + (index + 1) + "' id='optionId_" + (index + 1) + "' class='test' />";
      dtMarkups += "</dt>";
      dtMarkups += "</dl>";
      dtMarkups += "</li>";
    });

    dtMarkups += "<li class='optPrice'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>Price</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    dtMarkups += "<li class='optStock'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>Stock</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    dtMarkups += "<li class='optSku'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>SKU</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    dtMarkups += "</ul>";

    /* ==============================
		* DD 구성
		================================= */
    var allValues = [];
    $(".boxList", opt.boxInput).each(function (i) {
      var values = [];
      $(".optValueInp", $(this)).each(function (index) {
        const valueData = $(this)?.data("chk")?.split(";");
        const valueIdData = $(this).attr("readonly") != undefined ? valueData[0] : 0;
        const image = $("#optImage" + (i + 1) + "_" + (index + 1)).val() === undefined ? "" : $("#optImage" + (i + 1) + "_" + (index + 1)).val();
        const value = {
          valueName: $(this).val(),
          valueId: valueIdData,
          optionId: valueData[1],
          order: index + 1,
          image: image,
        };

        // Find matching item in the data array
        const matchedItem = opt.bodyData.find(
          (item) => item[`valueId_${value.optionId}`] === value.valueId && item[`optionId_${value.optionId}`] === value.optionId && item[`valueName_${value.optionId}`] === value.valueName
        );

        // If a match is found, add sku, stock, and price
        if (matchedItem) {
          value.id = matchedItem[`id_${value.optionId}`];
          value.sku = matchedItem.sku;
          value.stock = matchedItem.stock;
          value.price = matchedItem.price;
        } else {
          value.id = 0;
          value.sku = "";
          value.stock = 0;
          value.price = 0;
        }

        values.push(value);
      });

      allValues.push(values);
    });

    function combine(arr, n = 0, result = [], current = []) {
      if (n === arr.length) {
        result.push(current.slice());
        return;
      }
      for (var i = 0; i < arr[n].length; i++) {
        current.push(arr[n][i]);
        combine(arr, n + 1, result, current);
        current.pop();
      }
    }

    // Combine `valueName_` key values with their `price`, `stock`, and `sku`
    const combinedData = opt.bodyData.map((item) => {
      // Concatenate all valueName_ fields for each item
      const valueNames = Object.keys(item)
        .filter((key) => key.startsWith("valueName_"))
        .map((key) => item[key]) // Extract the value
        .join(";"); // Join with semicolons

      const idNames = Object.keys(item)
        .filter((key) => key.startsWith("variationId_"))
        .map((key) => item[key]) // Extract the value
        .join(";"); // Join with semicolons

      return {
        combinedValueNames: valueNames,
        combinedIdValues: idNames,
        combinedValues: `${item.price};${item.stock};${item.sku};${item.image}`,
      };
    });

    var combinations = [];
    combine(allValues, 0, combinations);
    var stock = "0";
    var price = "0";
    var sku = "";
    var image = "";
    var variationId = "0";

    $.each(combinations, function (i, combination) {
      ddMarkups += "<ul class='boxList'>";
      ddMarkups += "<li class='optNum'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>순번</dt>";
      ddMarkups += "<dd><div class='lv1Unit unit'><div class='lv1CloneTarget num'>" + (i + 1) + "</div></div></dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";

      $.each(combination, function (index, item) {
        ddMarkups += "<li class='optCore'>";
        ddMarkups += "<dl>";
        ddMarkups += "<dt>" + item + "</dt>";
        ddMarkups += "<dd>";
        ddMarkups += "<div class='lv1Unit unit'><div class='lv1CloneTarget'>";
        ddMarkups +=
          "<input type='text' class='optNameInp' value='" +
          item.valueName +
          "' name='optRow" +
          (i + 1) +
          "_item" +
          (index + 1) +
          "' id='optRow" +
          (i + 1) +
          "_item" +
          (index + 1) +
          "' readonly=true />";
        ddMarkups +=
          "<input type='hidden' class='optNameInpHidden' value='" + item.valueId + "' name='optRowId" + (i + 1) + "_item" + (index + 1) + "' id='optRowId" + (i + 1) + "_item" + (index + 1) + "' />";
        ddMarkups += "<input type='hidden'  name='optRow" + (i + 1) + "_id" + (index + 1) + "'  value='" + variationId + "'  id='optRow" + (i + 1) + "_id" + (index + 1) + "' />";
        if (item.image !== "") {
          ddMarkups += "<span class='optImg'>";
          ddMarkups +=
            "<img src='" +
            item.image +
            "' alt='Image' width='40' height='40' /><input type='hidden' name='optRow" +
            (i + 1) +
            "_image" +
            (index + 1) +
            "'  id='optRow" +
            (i + 1) +
            "_image" +
            (index + 1) +
            "'  value='" +
            item.image +
            "'  />";
          ddMarkups += "</span>";
        }
        ddMarkups += "</div></div>";
        ddMarkups += "</dd>";
        ddMarkups += "</dl>";
        ddMarkups += "</li>";
      });

      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "<li class='optPrice'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>가격</dt>";
      ddMarkups += "<dd>";
      ddMarkups += "<div class='lv2Unit unit'>";
      ddMarkups += "<div class='lv2CloneTarget'>";
      ddMarkups += "<input type='text' class='w100p' name='optRow" + (i + 1) + "_price'  value='" + price + "' id='optRow" + (i + 1) + "_price' />";
      ddMarkups += "</div>";
      ddMarkups += "</div>";
      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "<li class='optStock'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>수량</dt>";
      ddMarkups += "<dd>";
      ddMarkups += "<div class='lv2Unit unit'>";
      ddMarkups += "<div class='lv2CloneTarget'>";
      ddMarkups += "<input type='text' class='w100p' name='optRow" + (i + 1) + "_stock' value='" + stock + "' id='optRow" + (i + 1) + "_stock' />";
      ddMarkups += "</div>";
      ddMarkups += "</div>";
      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "<li class='optSku'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>SKU</dt>";
      ddMarkups += "<dd>";
      ddMarkups += "<div class='lv2Unit unit'>";
      ddMarkups += "<div class='lv2CloneTarget'>";
      ddMarkups += "<input type='text' class='w100p' name='optRow" + (i + 1) + "_sku' value='" + sku + "' id='optRow" + (i + 1) + "_sku' readonly=true />";
      ddMarkups += "</div>";
      ddMarkups += "</div>";
      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "</ul>";
    });

    /* ==============================
		* DT + DD 마크업 구성
		================================= */
    $(".boxListWr", opt.boxApplied).html(dtMarkups + ddMarkups);

    /* ==============================
		* boxApplied 목록 값(Input) 재산정.
		================================= */
    $(".boxList", opt.boxApplied).each(function (index, item) {
      let combinedText = ""; // Initialize an empty string for each boxList item

      $(".optCore", $(this)).each(function (index2, item) {
        let thisVal = $("input", $(this)).val(); // Get the value of the input within optCore
        combinedText += thisVal + ";"; // Append the value to combinedText

        // Add a space between each word, if needed
        if (index2 < $(".optCore", $(this)).length - 1) {
          combinedText += " "; // Add a space after each value except the last one
        }
      });
      const coreName = combinedText.slice(0, -1);
      $(".optCore", $(this)).each(function (i, item) {
        if (combinedData.length > 0) {
          combinedData.forEach((combined) => {
            if (combined.combinedValueNames === coreName) {
              const arrayCombinedValues = combined.combinedIdValues.split(";");
              $("#optRow" + index + "_id" + (i + 1)).val(arrayCombinedValues[i]);
            }
          });
        }
      });

      if (combinedData.length > 0) {
        combinedData.forEach((combined) => {
          if (combined.combinedValueNames === coreName) {
            const arrayCombinedValues = combined.combinedValues.split(";");
            $("#optRow" + index + "_price").val(parseInt(arrayCombinedValues[0]));
            $("#optRow" + index + "_stock").val(parseInt(arrayCombinedValues[1]));
            $("#optRow" + index + "_sku").val(arrayCombinedValues[2]);
            $("#optRow" + index + "_image").val(arrayCombinedValues[3]);
          }
        });
      }
    });
  }
  render() {
    return <></>;
  }
}

/* ==============================
* Seller > Prodict Register
================================= */
export class ProdictRegisterPkg2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opt: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.targetParent = $(this.props.targetParent);
    this.boxInput = $(".boxInput", this.targetParent);
    this.boxApplied = $(".boxApplied", this.targetParent);
    this.lv1Parent = $(".boxListWr", this.targetParent);
    this.lv1Lim = this.props.lv1Lim;
    this.lv2Parent = null;
    this.lv2Lim = this.props.lv2Lim;
    this.bodyData = this.props.bodyData;
    this.optApply = $(".optApply", this.targetParent);
    this.evtCast();
  }
  evtCast() {
    const opt = this;

    // ** Minus 클릭
    opt.targetParent.on("click", ".minus", function () {
      opt.setMinus($(this));
    });

    // ** Plus 클릭
    opt.targetParent.on("click", ".plus", function () {
      opt.setPlus($(this));
    });

    // ** 옵션 적용
    opt.optApply.on("click", function () {
      opt.setApply();
    });

    opt.boxInput.on("click", ".radioBtn", function () {
      console.log($(".optImage").length);
      const radioValue = $(this).val();

      $(".optImage").each(function (index) {
        if (index + 1 === parseInt(radioValue)) {
          $(".imageBtn" + radioValue).show(); // radioValue와 일치하는 버튼만 표시
        } else {
          $(".imageBtn" + (index + 1)).hide(); // radioValue와 일치하는 버튼만 표시
          $(".hiddenImage").val("");
          $(".optImageValueId").val("");
          $(".imgView").attr("src", "");
          $(".upImg").show();
          $(".imgView").hide();
          $(".imgDel").hide();
        }
      });
    });

    opt.targetParent.on("click", ".upImg", function () {
      const inputId = $(this).find('input[type="file"]').attr("id");
      const fileInput = document.getElementById(inputId);
      if (fileInput) {
        fileInput.click(); // input 클릭 이벤트 트리거
      }
    });

    opt.targetParent.on("change", ".upImg", function (event) {
      const file = event.target.files[0];
      const inputId = $(this).find('input[type="file"]').attr("id");
      const fileId = inputId.replace("optionFile", ""); // 현재 input의 ID 가져오기
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          $("#imgOptionFile" + fileId).show();
          $("#imgOptionFile" + fileId).append("<img src='" + reader.result + "' id='fileImg" + fileId + "' alt='Preview'  class ='imgView'/>");
          $("#imgOptionDel" + fileId).show();
          $("#btnOptionFile" + fileId).hide();
          saveFileImage(reader.result, fileId);
        };
        reader.readAsDataURL(file);
      }

      const getMimeTypeFromDataURI = (dataURI) => {
        const mimeType = dataURI.split(";")[0].replace("data:", ""); // 'data:image/jpeg'에서 'image/jpeg' 부분 추출
        return mimeType;
      };

      const base64ToFileWithName = (base64String, fileName, mimeType) => {
        const base64Data = base64String.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset++) {
          const byteValue = byteCharacters.charCodeAt(offset);
          byteArrays.push(byteValue);
        }

        const byteArray = new Uint8Array(byteArrays);
        const blob = new Blob([byteArray], { type: mimeType });

        // 파일 이름을 받아서 File 객체를 생성
        const file = new File([blob], fileName, { type: mimeType });

        return file;
      };

      const getFileExt = (mimeType) => {
        let fileExt = "";
        if (mimeType === "image/jpeg") {
          fileExt = "jpg";
        } else {
          fileExt = mimeType.replace("image/", "");
        }
        return fileExt;
      };

      const saveFileImage = async (fileImage, idx) => {
        const mimeType = getMimeTypeFromDataURI(fileImage);
        const fileExt = getFileExt(mimeType);
        const now = new Date();
        const dateString = now.toISOString().slice(0, 19).replace(/-/g, "").replace(/:/g, "").replace("T", "-");
        // 6자리 랜덤 숫자
        const randomNumber = Math.floor(100000 + Math.random() * 900000);

        const fileName = "SalesOption/" + `${dateString}-${randomNumber}.` + fileExt;

        const file = base64ToFileWithName(fileImage, fileName, mimeType);
        const modifiedImage = new File([file], fileName, { type: file.type, lastModified: file.lastModified });
        const formData = new FormData();
        formData.append("file", modifiedImage);

        const url = "seller/content/type/file?fileContentType=PRODUCT";
        const res = await WebService.post(url, formData, { headers: { "Content-Type": "multipart/form-data" } });
        if (res) {
          if ($("#optImage" + idx)) {
            $("#optImage" + idx).val(res);
          }
        }
      };
    });

    opt.targetParent.on("click", ".delImg", function () {
      const inputId = $(this).attr("id");
      const fileId = inputId.replace("imgOptionDelFile", "");
      $("#optRow" + fileId + "_image").val("");
      $("#imgOptionFile" + fileId).hide();
      $("#imgOptionDel" + fileId).hide();
      $("#fileImg" + fileId).remove();
      $("#imgOptionFile" + fileId).append("");
      $("#btnOptionFile" + fileId).show();
      $("#optionFile" + fileId).val("");
    });
  }

  setPlus(btn) {
    const opt = this;
    if (btn.hasClass("lv1")) {
      let lv1Len = btn.closest(".boxListWr").find(".boxList").length;
      if (opt.lv1Lim <= lv1Len) {
        alert("최대 " + lv1Len + "개 까지만 설정이 가능합니다.");
        return;
      } else {
        let cloneTarget = btn.closest(".boxList").clone();
        $("input", cloneTarget).val("");
        $("input", cloneTarget).attr("data-id", 0);
        $("input", cloneTarget).attr("readonly", false);
        $(".lv2Unit", cloneTarget).each(function () {
          $(".lv2CloneTarget", $(this)).slice(1).remove();
          $(".lv2CloneTarget", $(this)).find("input").attr("data-chk", "0;0");
          $(".lv2CloneTarget", $(this)).find("input").removeAttr("data-id");
          $(".lv2CloneTarget", $(this)).find("input").attr("readonly", false);
        });
        btn.closest(".boxListWr").append(cloneTarget);
      }
    } else if (btn.hasClass("lv2")) {
      let lv2Len = btn.closest("dd").find(".lv2CloneTarget").length;
      if (opt.lv2Lim <= lv2Len) {
        alert("최대 " + lv2Len + "개 까지만 설정이 가능합니다.");
        return;
      } else {
        $(".lv2Unit", btn.closest(".boxList")).each(function (index, item) {
          let cloneTarget = $(".lv2CloneTarget", $(this)).first().clone();
          let optionId = $("input", cloneTarget).data("chk").split(";")[1];
          $("input", cloneTarget).val("");
          $("input", cloneTarget).attr("data-id", 0);
          $("input", cloneTarget).attr("data-chk", "0;" + optionId);
          $("input", cloneTarget).attr("readonly", false);
          $(this).append(cloneTarget);
        });
      }
    }
    opt.setArrange();
  }
  setMinus(btn) {
    const opt = this;
    if (btn.hasClass("lv1")) {
      btn.closest(".boxList").remove();
    } else if (btn.hasClass("lv2")) {
      let thisIndex = btn.parent().index();
      $(".lv2Unit", btn.closest(".boxList")).each(function (index, item) {
        $(".lv2CloneTarget", $(this)).eq(thisIndex).remove();
      });
    }
    opt.setArrange();
  }
  setArrange() {
    const opt = this;
    $(".boxList", opt.boxInput).each(function (index, item) {
      let orderNum1 = index + 1;

      // ** .boxList 클래스 순번 지정.
      var classes = $(item).attr("class").split(" ");
      var newClasses = classes.filter(function (c) {
        return c === "boxList";
      });
      $(item).attr("class", newClasses.join(" "));
      $(item).addClass("list" + orderNum1);

      // ** 1Depth 이외 input 비활성(disabled) 로직.
      if (index !== 0) {
        $(".optStockInp, .optSkuInp", $(item)).prop({ disabled: true });
      }

      // ** 순번 표시.
      $(".optNum .lv1CloneTarget", $(item)).text(orderNum1);

      // ** 속성 순번 적용.
      opt.setAttrOrder($(item), orderNum1);
    });
  }
  setAttrOrder(el, order1) {
    let orderNum2 = "";

    // ** LV.1 / ID, NAME 순번 재설정.
    $(".optName .optNameInp", el).attr({ id: "optName" + order1, name: "optName" + order1, value: "" });
    $(".optId ", el).attr({ id: "optId" + order1, name: "optId" + order1, value: "" });

    // ** LV.2 / ID, NAME 순번 재설정.
    $(".optValue .lv2CloneTarget", el).each(function (index, item) {
      orderNum2 = index + 1;
      $(".optValueInp", $(item)).attr({ id: "optValue" + order1 + "_" + orderNum2, name: "optValue" + order1 + "_" + orderNum2 });
      $(".optValueInpHidden", $(item)).attr({ id: "optId" + order1 + "_" + orderNum2, name: "optId" + order1 + "_" + orderNum2 });
    });

    $(".optImage .lv2CloneTarget", el).each(function (index, item) {
      orderNum2 = index + 1;

      $(".imgView", $(item)).attr("id", "imgOptionFile" + order1 + "_" + orderNum2);
      $(".hiddenImage", $(item)).attr("id", "optImage" + order1 + "_" + orderNum2);
      $(".hiddenImage", $(item)).val("");

      $(".imgDel", $(item)).attr("id", "imgOptionDel" + order1 + "_" + orderNum2);
      $(".delImg", $(item)).attr("id", "imgOptionDelFile" + order1 + "_" + orderNum2);
      $(".upImg", $(item)).attr("id", "btnOptionFile" + order1 + "_" + orderNum2);
      $(".fileUpload", $(item)).attr("id", "optionFile" + order1 + "_" + orderNum2);
    });
  }
  setApply() {
    const opt = this;
    let dtMarkups = "";
    let ddMarkups = "";

    /* ==============================
		* DT 구성
		================================= */
    dtMarkups += "<ul class='boxList'>";
    dtMarkups += "<li class='optNum'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>No.</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    $(".boxList", opt.boxInput).each(function (index, item) {
      dtMarkups += "<li class='optCore'>";
      dtMarkups += "<dl>";
      dtMarkups += "<dt>";
      dtMarkups += "<input type='text' value='" + $(".optNameInp", $(this)).val() + "' name='optName_" + (index + 1) + "' id='optName_" + (index + 1) + "' readonly=true />";
      dtMarkups += "<input type='hidden' value='" + $(".optNameInp", $(this)).data("id") + "' name='optId_" + (index + 1) + "' id='optionId_" + (index + 1) + "' class='test' />";
      dtMarkups += "</dt>";
      dtMarkups += "</dl>";
      dtMarkups += "</li>";
    });

    dtMarkups += "<li class='optStock'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>Stock</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    dtMarkups += "<li class='optSku'>";
    dtMarkups += "<dl>";
    dtMarkups += "<dt>SKU</dt>";
    dtMarkups += "</dl>";
    dtMarkups += "</li>";
    dtMarkups += "</ul>";

    /* ==============================
		* DD 구성
		================================= */
    var allValues = [];
    $(".boxList", opt.boxInput).each(function (i) {
      var values = [];
      $(".optValueInp", $(this)).each(function (index, item2) {
        const valueData = $(this).data("chk").split(";");
        const valueIdData = $(this).attr("readonly") != undefined ? valueData[0] : 0;
        const image = $("#optImage" + (i + 1) + "_" + (index + 1)).val() === undefined ? "" : $("#optImage" + (i + 1) + "_" + (index + 1)).val();
        const value = {
          valueName: $(this).val(),
          valueId: valueIdData,
          optionId: valueData[1],
          order: index + 1,
          image: image,
        };

        // Find matching item in the data array
        const matchedItem = opt.bodyData.find(
          (item) => item[`valueId_${value.optionId}`] === value.valueId && item[`optionId_${value.optionId}`] === value.optionId && item[`valueName_${value.optionId}`] === value.valueName
        );

        // If a match is found, add sku, stock, and price
        if (matchedItem) {
          value.id = matchedItem[`id_${value.optionId}`];
          value.sku = matchedItem.sku;
          value.stock = matchedItem.stock;
        } else {
          value.id = 0;
          value.sku = "";
        }

        values.push(value);
      });

      allValues.push(values);
    });

    function combine(arr, n = 0, result = [], current = []) {
      if (n === arr.length) {
        result.push(current.slice());
        return;
      }
      for (var i = 0; i < arr[n].length; i++) {
        current.push(arr[n][i]);
        combine(arr, n + 1, result, current);
        current.pop();
      }
    }

    // Combine `valueName_` key values with their `price`, `stock`, and `sku`
    const combinedData = opt.bodyData.map((item) => {
      // Concatenate all valueName_ fields for each item
      const valueNames = Object.keys(item)
        .filter((key) => key.startsWith("valueName_"))
        .map((key) => item[key]) // Extract the value
        .join(";"); // Join with semicolons

      const idNames = Object.keys(item)
        .filter((key) => key.startsWith("variationId_"))
        .map((key) => item[key]) // Extract the value
        .join(";"); // Join with semicolons

      return {
        combinedValueNames: valueNames,
        combinedIdValues: idNames,
        combinedValues: `${item.stock};${item.sku}`,
      };
    });

    var combinations = [];
    combine(allValues, 0, combinations);
    var stock = "0";
    var sku = "";
    var variationId = "0";
    $.each(combinations, function (i, combination) {
      ddMarkups += "<ul class='boxList'>";
      ddMarkups += "<li class='optNum'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>순번</dt>";
      ddMarkups += "<dd><div class='lv1Unit unit'><div class='lv1CloneTarget num'>" + (i + 1) + "</div></div></dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      $.each(combination, function (index, item) {
        ddMarkups += "<li class='optCore'>";
        ddMarkups += "<dl>";
        ddMarkups += "<dt>" + item + "</dt>";
        ddMarkups += "<dd>";
        ddMarkups += "<div class='lv1Unit unit'><div class='lv1CloneTarget'>";
        ddMarkups +=
          "<input type='text' class='optNameInp' value='" +
          item.valueName +
          "' name='optRow" +
          (i + 1) +
          "_item" +
          (index + 1) +
          "' id='optRow" +
          (i + 1) +
          "_item" +
          (index + 1) +
          "' readonly=true />";
        ddMarkups +=
          "<input type='hidden' class='optNameInpHidden' value='" + item.valueId + "' name='optRowId" + (i + 1) + "_item" + (index + 1) + "' id='optRowId" + (i + 1) + "_item" + (index + 1) + "'>";
        ddMarkups += "<input type='hidden'  name='optRow" + (i + 1) + "_id" + (index + 1) + "'  value='" + variationId + "'  id='optRow" + (i + 1) + "_id" + (index + 1) + "' />";
        if (item.image !== "") {
          ddMarkups += "<span class='optImg'>";
          ddMarkups +=
            "<img src='" +
            item.image +
            "' alt='Image' width='40' height='40' /><input type='hidden' name='optRow" +
            (i + 1) +
            "_image" +
            (index + 1) +
            "'  id='optRow" +
            (i + 1) +
            "_image" +
            (index + 1) +
            "'  value='" +
            item.image +
            "'  />";
          ddMarkups += "</span>";
        }
        ddMarkups += "</div></div>";
        ddMarkups += "</dd>";
        ddMarkups += "</dl>";
        ddMarkups += "</li>";
      });

      ddMarkups += "<li class='optStock'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>수량</dt>";
      ddMarkups += "<dd>";
      ddMarkups += "<div class='lv2Unit unit'>";
      ddMarkups += "<div class='lv2CloneTarget'>";
      ddMarkups += "<input type='text' class='w100p' name='optRow" + (i + 1) + "_stock'   value='" + stock + "'  id='optRow" + (i + 1) + "_stock' />";
      ddMarkups += "</div>";
      ddMarkups += "</div>";
      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "<li class='optSku'>";
      ddMarkups += "<dl>";
      ddMarkups += "<dt>SKU</dt>";
      ddMarkups += "<dd>";
      ddMarkups += "<div class='lv2Unit unit'>";
      ddMarkups += "<div class='lv2CloneTarget'>";
      ddMarkups += "<input type='text' class='w100p' name='optRow" + (i + 1) + "_sku' value='" + sku + "' id='optRow" + (i + 1) + "_sku' readonly=true />";
      ddMarkups += "</div>";
      ddMarkups += "</div>";
      ddMarkups += "</dd>";
      ddMarkups += "</dl>";
      ddMarkups += "</li>";
      ddMarkups += "</ul>";
    });

    /* ==============================
		* DT + DD 마크업 구성
		================================= */
    $(".boxListWr", opt.boxApplied).html(dtMarkups + ddMarkups);

    /* ==============================
		* boxApplied 목록 값(Input) 재산정.
		================================= */
    $(".boxList", opt.boxApplied).each(function (index, item) {
      let combinedText = ""; // Initialize an empty string for each boxList item

      $(".optCore", $(this)).each(function (index2, item) {
        let thisVal = $("input", $(this)).val(); // Get the value of the input within optCore
        combinedText += thisVal + ";"; // Append the value to combinedText

        // Add a space between each word, if needed
        if (index2 < $(".optCore", $(this)).length - 1) {
          combinedText += " "; // Add a space after each value except the last one
        }
      });

      const coreName = combinedText.slice(0, -1);
      $(".optCore", $(this)).each(function (i, item) {
        if (combinedData.length > 0) {
          combinedData.forEach((combined) => {
            if (combined.combinedValueNames === coreName) {
              const arrayCombinedValues = combined.combinedIdValues.split(";");
              $("#optRow" + index + "_id" + (i + 1)).val(arrayCombinedValues[i]);
            }
          });
        }
      });

      if (combinedData.length > 0) {
        combinedData.forEach((combined) => {
          if (combined.combinedValueNames === coreName) {
            const arrayCombinedValues = combined.combinedValues.split(";");
            $("#optRow" + index + "_stock").val(parseInt(arrayCombinedValues[0]));
            $("#optRow" + index + "_sku").val(arrayCombinedValues[1]);
          }
        });
      }
    });
  }
  render() {
    return <></>;
  }
}

/* ==============================
* CommingSoon
================================= */
export const CommingSoon = (message) => {
  alert(message);
};

/* ==============================
* Item number controller (상품등록)
================================= */
/*
export class CurrencyExchanger extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			opt: {}
		};
	}
	componentDidMount() {
		this.init();
	}
	init() {
		this.currencyWr = $(this.props.currencyWr);
		this.currencyCast = $("#setExchangeRateCast", this.currencyWr );
		this.currencyBtns = $(".langSet button", this.currencyWr );
		this.evtCast();
	}
	evtCast() {
		const lang = this;

		// ** 환율 Api 가동
		lang.currencyCast.on("click", function() {
			(async () => {
				await lang.exchangeApiCast();
			})();
		});

		// ** 국가선택
		lang.currencyBtns.on("click", function() {
			console.log( "@@@" );
		});
	}
	async exchangeApiCast() {
		try {
			const response = await fetch( "https://www.koreaexim.go.kr/site/program/financial/exchangeJSON?authkey=HSKEPK5RRe1ssuj43knIQVuZic62K3fV&data=AP01" );
			console.log("response : ", response);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();

			data[13].result = 1;
			if (data[13].result === 1) {
				setRateKRW(data[13].deal_bas_r);
			} else {
				setRateKRW("");
				alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
				return;
			}
			if (data[6].result === 1) {
				setRateCNH(data[6].deal_bas_r);
			} else {
				setRateCNH("");
				alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
				return;
			}
			if (data[22].result === 1) {
				setRateUSD(data[22].deal_bas_r);
			} else {
				setRateUSD("");
				alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
				return;
			}
			if (data[12].result === 1) {
				setRateJPY(data[12].deal_bas_r);
			} else {
				setRateJPY("");
				alert("통신이 원활하지 못해 원하시는 통화로 변경할수 없습니다.");
				return;
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}
	render() {
		return <></>;
	}
}
// */

//
export function currencyChangeMethod(deal_bas_r, price, nation) {
  // 환율 변환 공식
  // 원화금액 / 각국 매매기준율
  // 엔화는 일반적으로 소수점 이하 두자리가 없기 대무에 100을 곱해준다.
  // 문자열로 변환 후 쉼표 제거
  const priceStr = String(price).replace(/,/g, "");
  const deal_bas_rStr = String(deal_bas_r).replace(/,/g, "");

  // 숫자로 변환 후 반올림
  const priceFloat = parseFloat(priceStr);
  const deal_bas_rFloat = parseFloat(deal_bas_rStr);

  let result;
  switch (nation) {
    case "ko":
      result = priceFloat;
      break;
    case "ja":
      result = (priceFloat / deal_bas_rFloat) * 100;
      break;
    case "en":
    case "zh-CN":
    default:
      result = priceFloat / deal_bas_rFloat;
      break;
  }

  // 소수점 둘째 자리에서 올림
  result = Math.ceil(result * 100) / 100;

  return result;
}

const mapStateToProps = (state) => ({
  dealBasR: state.merchantData.code, // 실제 상태에 맞게 조정
  nation: state.multilanguage.currentLanguageCode,
});

/* ==============================
* Currency changer ( KR : 소수점 1자리에서 올림 )
================================= */
export class CurrencyChangerEachNations extends Component {
  constructor(props) {
    super(props);
    this.setChar = this.setChar.bind(this);
  }

  setChar() {
    const { targetChar, deal_bas_r, nation } = this.props;

    if (!targetChar) return "";
    const numericString = targetChar.toString().replace(/[^0-9.]/g, ""); // ** 숫자와 마침표만 남기고 제거
    const number = parseFloat(numericString); // ** 숫자로 변환

    if (isNaN(number)) return "";

    const convertedNumber = currencyChangeMethod(deal_bas_r, number, nation);
    const roundedNumber = Math.ceil(convertedNumber); // ** 소수점 첫째 자리에서 올림
    return parseInt(roundedNumber, 10); // 정수로 반환
  }
  formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  render() {
    const formattedNumber = this.formatNumberWithCommas(this.setChar());
    const formattedNumberOnly = this.setChar();
    const { nation } = this.props;
    let currencySymbol = "";
    if (nation === "ko") {
      currencySymbol = "₩";
    } else if (nation === "en") {
      currencySymbol = "$";
    } else if (nation === "ja") {
      currencySymbol = "¥";
    } else if (nation === "zh-CN") {
      currencySymbol = "¥";
    } else {
      currencySymbol = "₩";
    }
    return (
      <>
        <span className="currencyPrice">
          {currencySymbol}
          {formattedNumber}
        </span>
        <span className="currencyPriceNumber hidden">{formattedNumberOnly}</span>
      </>
    );
  }
}

export default connect(mapStateToProps)(CurrencyChangerEachNations);
