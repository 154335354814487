import { krUrls, jpUrls, usUrls } from ".";

const BASE_API = process.env.REACT_APP_BACKEND;

const getBackendUrl = () => {
  const domain = window.location.hostname;

  if (krUrls === domain) {
    return BASE_API;
  }

  if (jpUrls === domain) {
    return BASE_API;
  }

  if (usUrls === domain) {
    return BASE_API;
  }

  return BASE_API;
};

export const REACT_APP_BACKEND_API = getBackendUrl();
