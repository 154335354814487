import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import WebService from "../../util/webService";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { setLocalData, getLocalData } from "../../util/helper";

const Footer = ({ sideMenu, company, strings, currentLanguageCode }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [etcMenu, setEtcMenu] = useState([]);
  const footerMenu = useCallback(async () => {
    const topMenu = getLocalData("topMenu");
    const footerMenu = JSON.parse(topMenu ?? "[]");
    const filteredMenu = Array.isArray(footerMenu) ? footerMenu.filter((item) => item.id === 604) : [];
    if (filteredMenu.length > 0 && Array.isArray(filteredMenu[0].children)) {
      setEtcMenu([...filteredMenu[0].children]);
    }
  }, [etcMenu]);
  useEffect(() => {
    footerMenu();
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  const handleEmailClick = (event) => {
    event.preventDefault();
    const email = "info@sourcingroot.com";
    if (email) {
      window.location.href = `mailto:${email}`;
    }
  };

  return (
    <footer id="footer-wrap">
      <div className="container1">
        <ul>
          {etcMenu.map((footMenuItem) => {
            return (
              <li key={footMenuItem.id}>
                <Link to={footMenuItem.url}>
                  {currentLanguageCode && currentLanguageCode === "ko" ? (
                    <span>{footMenuItem.menuName}</span>
                  ) : currentLanguageCode === "en" ? (
                    <span>{footMenuItem.menuNameEn}</span>
                  ) : currentLanguageCode === "zh" ? (
                    <span>{footMenuItem.menuNameCn}</span>
                  ) : currentLanguageCode === "jp" ? (
                    <span>{footMenuItem.menuNameJp}</span>
                  ) : (
                    <span>{footMenuItem.menuName}</span>
                  )}
                </Link>
              </li>
            );
          })}
          {/* <li>
						<Link to="/styleguide">
						<span>{strings["Style guide"]}</span>
						</Link>
					</li>
					<li>
						<Link to="/appguide">
						<span>{strings["App guide"]}</span>
						</Link>
					</li> */}
        </ul>
      </div>
      <div className="container2">
        <div className="footCopy">
          <FooterCopyright footerLogo="" spaceBottomClass="" />
          <div className="sns">
            <ul className="sns_ul_wrapper">
              <li>
                <a href="https://pf.kakao.com/_kLxcxbxj" target="_blank" rel="noopener noreferrer">
                  <i className="ri-kakao-talk-line ico"></i>
                  <span className="txt blind">kakao</span>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UC9ORqA7ykaj4k7NeqTX9dpQ" target="_blank" rel="noopener noreferrer">
                  <i className="ri-youtube-line ico"></i>
                  <span className="txt blind">Youtube</span>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/sourcingroot_pet/" target="_blank" rel="noopener noreferrer">
                  <i className="ri-instagram-line ico"></i>
                  <span className="txt blind">Instagram</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footAddr">
          <ul className="list">
            <li className="footer_list">
              <dl>
                <dt>{strings["CKBRIDGE co.,ltd"]}</dt>
                <dd>{strings["CEO Hong Sung Ryong(Jacky)"]}</dd>
              </dl>
            </li>
            <li className="footer_list">
              <dl>
                <dt>{strings["Biz num"]}</dt>
                <dd>714-88-00766</dd>
              </dl>
            </li>
            <li className="footer_list">
              <dl>
                <dt>{strings["OnlineSales Biz num"]}</dt>
                <dd>{strings["2018-Nam-gu, Busan-0153"]}</dd>
              </dl>
            </li>
            <li className="footer_list">
              <dl>
                <dt>E-Mail</dt>
                <dd onClick={handleEmailClick}>info@sourcingroot.com</dd>
              </dl>
            </li>
            <li className="footer_list">
              <dl>
                <dt>{strings["Tel"]}</dt>
                <dd>1588-4889</dd>
              </dl>
            </li>
            <li className="footer_list w100p">
              <dl>
                <dt>{strings["Headquarters address"]}</dt>
                <dd>
                  {strings["company address"]}
                  {company?.address && (
                    <>
                      {company.address.city} {company.address.address}
                      {/* {company.address.stateProvince} */}
                      {/* {company.address.country} {company.address.postalCode} */}
                    </>
                  )}
                </dd>
              </dl>
            </li>
          </ul>
          <div className="msg">
            <p>{strings["Footer_Sourcingroot is a mail order intermediary and is not a party to the mail order."]}</p>
            <p>{strings["Footer_Obligations and responsibilities for products, product information, and transactions lie with the seller."]}</p>
          </div>
          <div className="copy">
            <p className="pTxt1">
              COPYRIGHT © 2024
              <Link to="/" rel="noopener noreferrer">
                {strings["sr"]}
              </Link>
              .
            </p>
            <p className="pTxt2">ALL RIGHTS RESERVED.</p>
          </div>
        </div>
        <div className="footUtil">
          <div className="inner">
            <dl className="csCenter">
              <dt>
                <i className="ri-customer-service-2-line ico"></i>
                <span className="txt">{strings["CS center"]}</span>
              </dt>
              <dd>
                <span className="num">
                  {/* {company?.phone} */}
                  1588-4889
                </span>
                <span className="guide">({strings["Paid"]})</span>
              </dd>
            </dl>
            <div className="msg">
              <span>10:00 ~ 18:00({strings["Korean time"]})</span>
              <span>{strings["Closed holidays"]}</span>
            </div>
            {/*
							<dl>
								<dt><i className="ri-links-line"></i> {strings["USEFUL LINKS"]}</dt>
								<dd>
									<ul>
										<li>
											<Link to={"/contact"}>{strings["Contact"]}</Link>
										</li>
										<li>
											<Link to={"/login"}>{strings["Login"]}</Link>
										</li>
										<li>
											<Link to={"/register"}>{strings["Register"]}</Link>
										</li>
									</ul>
								</dd>
							</dl>
						*/}
            {/*
							<FooterNewsletter strings={strings} spaceBottomClass="" spaceLeftClass="" sideMenu={sideMenu} />
						*/}
          </div>
        </div>
      </div>
      <button className={`scroll-top ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()}>
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};
Footer.propTypes = {
  currentLanguageCode: PropTypes.string,
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    company: state.merchantData.company,
  };
};
/*
const mapDispatchToProps = dispatch => {
	return {
	};
};
// */
export default connect(mapStateToProps, null)(multilanguage(Footer));
