import axios from "axios";
import { getLocalData } from "./helper";
import conf from "../config";
import { REACT_APP_BACKEND_API } from "../config/getBackendUrl";

const BASE_URL_1 = REACT_APP_BACKEND_API + window._env_.APP_API_VERSION;
const BASE_URL_2 = process.env.REACT_APP_APIS_URL + "/api/v1/external/";

axios.defaults.baseURL = BASE_URL_1;

const currentLanguageCode = JSON.parse(localStorage.getItem("redux_localstorage_simple"))?.multilanguage?.currentLanguageCode || conf.lang;

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = await getLocalData("token");
      config.headers.common["Authorization"] = token ? "Bearer " + token : "";

      const configQueryParams = {
        site: conf.site,
        lang: currentLanguageCode,
        currency: conf.currency,
      };

      const queryString = Object.entries(configQueryParams)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      const separator = config.url.includes("?") ? "&" : "?";
      config.url = `${config.url}${separator}${queryString}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response } = error;
      if (response.status === 401 || response.status === 404) {
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  );

  return instance;
};

const axiosInstance1 = createAxiosInstance(BASE_URL_1);
const axiosInstance2 = createAxiosInstance(BASE_URL_2);

export default class WebService {
  static async post(action, params) {
    let response = await axiosInstance1.post(action, params);
    return response.data;
  }

  static async put(action, params) {
    let response = await axiosInstance1.put(action, params);
    return response.data;
  }

  static async get(action, param) {
    let response = await axiosInstance1.get(action, param);
    return response.data;
  }
  static async get(action) {
    let response = await axiosInstance1.get(action);
    return response.data;
  }

  static async delete(action) {
    let response = await axiosInstance1.delete(action);
    return response.data;
  }

  static async deleteBody(action, param) {
    let response = await axiosInstance1.delete(action, { data: param });
    return response.data;
  }

  static async patch(action, params) {
    let response = await axiosInstance1.patch(action, params);
    return response.data;
  }

  static getQueryString(params) {
    return `?${Object.entries(params)
      .map((e) => e.join("="))
      .join("&")}`;
  }

  static EXT = {
    async post(action, params) {
      let response = await axiosInstance2.post(action, params);
      return response.data;
    },

    async put(action, params) {
      let response = await axiosInstance2.put(action, params);
      return response.data;
    },

    async get(action) {
      let response = await axiosInstance2.get(action);
      return response.data;
    },

    async delete(action) {
      let response = await axiosInstance2.delete(action);
      return response.data;
    },

    async patch(action, params) {
      let response = await axiosInstance2.patch(action, params);
      return response.data;
    },
  };
}
